import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';

const NikkeGuidesHarmonyData: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Harmony Cubes - data</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_cubedata.png"
            alt="Harmony Cubes - data"
          />
        </div>
        <div className="page-details">
          <h1>Harmony Cubes - data</h1>
          <h2>Detailed information about the Harmony Cubes in NIKKE.</h2>
          <p>
            Last updated: <strong>13/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Harmony Cube Data" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level</th>
              <th>ATK</th>
              <th>DEF</th>
              <th>HP</th>
              <th>Slots</th>
              <th>Credit Cost</th>
              <th>Battery Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>390</td>
              <td>78</td>
              <td>11,800</td>
              <td>1</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>2</td>
              <td>450</td>
              <td>90</td>
              <td>13,600</td>
              <td>2</td>
              <td>10,000</td>
              <td>10</td>
            </tr>
            <tr>
              <td>3</td>
              <td>520</td>
              <td>103</td>
              <td>15,600</td>
              <td>2</td>
              <td>20,000</td>
              <td>10</td>
            </tr>
            <tr>
              <td>4</td>
              <td>600</td>
              <td>119</td>
              <td>17,900</td>
              <td>3</td>
              <td>40,000</td>
              <td>30</td>
            </tr>
            <tr>
              <td>5</td>
              <td>690</td>
              <td>136</td>
              <td>20,600</td>
              <td>3</td>
              <td>80,000</td>
              <td>30</td>
            </tr>
            <tr>
              <td>6</td>
              <td>790</td>
              <td>157</td>
              <td>23,700</td>
              <td>4</td>
              <td>160,000</td>
              <td>50</td>
            </tr>
            <tr>
              <td>7</td>
              <td>910</td>
              <td>180</td>
              <td>27,300</td>
              <td>4</td>
              <td>240,000</td>
              <td>50</td>
            </tr>
            <tr>
              <td>8</td>
              <td>1,050</td>
              <td>207</td>
              <td>31,400</td>
              <td>5</td>
              <td>320,000</td>
              <td>70</td>
            </tr>
            <tr>
              <td>9</td>
              <td>1,200</td>
              <td>239</td>
              <td>36,100</td>
              <td>5</td>
              <td>400,000</td>
              <td>70</td>
            </tr>
            <tr>
              <td>10</td>
              <td>1,380</td>
              <td>274</td>
              <td>41,500</td>
              <td>6</td>
              <td>480,000</td>
              <td>100</td>
            </tr>
            <tr>
              <td>11</td>
              <td>1,590</td>
              <td>316</td>
              <td>47,700</td>
              <td>8</td>
              <td>480,000</td>
              <td>100</td>
            </tr>
            <tr>
              <td>12</td>
              <td>1,830</td>
              <td>363</td>
              <td>54,900</td>
              <td>8</td>
              <td>480,000</td>
              <td>100</td>
            </tr>
            <tr>
              <td>13</td>
              <td>2,100</td>
              <td>417</td>
              <td>63,100</td>
              <td>10</td>
              <td>480,000</td>
              <td>100</td>
            </tr>
            <tr>
              <td>14</td>
              <td>2,420</td>
              <td>480</td>
              <td>72,500</td>
              <td>10</td>
              <td>480,000</td>
              <td>100</td>
            </tr>
            <tr>
              <td>15</td>
              <td>2,780</td>
              <td>552</td>
              <td>83,400</td>
              <td>10</td>
              <td>480,000</td>
              <td>100</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Harmony Cube Skills" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level</th>
              <th>Assault Cube (Hit Rate)</th>
              <th>Onslaught Cube (Charge Damage)</th>
              <th>Resilience Cube (Reload Speed)</th>
              <th>Bastion Cube (Ammo Refund)</th>
              <th>Adjutant Cube (Charge Speed)</th>
              <th>Wingman Cube (Max Ammo)</th>
              <th>Damage as strong element</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2.54%</td>
              <td>2.54%</td>
              <td>14.84%</td>
              <td>1</td>
              <td>1.06%</td>
              <td>14.84%</td>
              <td>-</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2.54%</td>
              <td>2.54%</td>
              <td>14.84%</td>
              <td>1</td>
              <td>1.06%</td>
              <td>14.84%</td>
              <td>-</td>
            </tr>
            <tr>
              <td>3</td>
              <td>3.81%</td>
              <td>3.81%</td>
              <td>22.27%</td>
              <td>2</td>
              <td>1.59%</td>
              <td>22.29%</td>
              <td>-</td>
            </tr>
            <tr>
              <td>4</td>
              <td>3.81%</td>
              <td>3.81%</td>
              <td>22.27%</td>
              <td>2</td>
              <td>1.59%</td>
              <td>22.29%</td>
              <td>-</td>
            </tr>
            <tr>
              <td>5</td>
              <td>3.81%</td>
              <td>3.81%</td>
              <td>22.27%</td>
              <td>2</td>
              <td>1.59%</td>
              <td>22.29%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>3.81%</td>
              <td>3.81%</td>
              <td>22.27%</td>
              <td>2</td>
              <td>1.59%</td>
              <td>22.29%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>8</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>10.6%</td>
            </tr>
            <tr>
              <td>10</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>12.72%</td>
            </tr>
            <tr>
              <td>11</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>14.84%</td>
            </tr>
            <tr>
              <td>12</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>14.84%</td>
            </tr>
            <tr>
              <td>13</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>16.96%</td>
            </tr>
            <tr>
              <td>14</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>16.96%</td>
            </tr>
            <tr>
              <td>15</td>
              <td>5.09%</td>
              <td>5.09%</td>
              <td>29.69%</td>
              <td>3</td>
              <td>2.12%</td>
              <td>29.69%</td>
              <td>19.09%</td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level</th>
              <th>Quantum Cube (Charge Speed)</th>
              <th>Vigor Cube (Max HP)</th>
              <th>Endurance (DEF)</th>
              <th>Healing (HP Potency)</th>
              <th>Tempering (Damage Taken ▼)</th>
              <th>Assist (Critical Max HP ▲)</th>
              <th>Destruction (Part Damage ▲)</th>
              <th>Damage as strong element</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2.33%</td>
              <td>4.84%</td>
              <td>24.20%</td>
              <td>8.08%</td>
              <td>4.84%</td>
              <td>6.07%</td>
              <td>15.95%</td>
              <td>-</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2.33%</td>
              <td>4.84%</td>
              <td>24.20%</td>
              <td>8.08%</td>
              <td>4.84%</td>
              <td>6.07%</td>
              <td>15.95%</td>
              <td>-</td>
            </tr>
            <tr>
              <td>3</td>
              <td>3.5%</td>
              <td>7.27%</td>
              <td>36.35%</td>
              <td>12.12%</td>
              <td>7.27%</td>
              <td>9.10%</td>
              <td>23.92%</td>
              <td>-</td>
            </tr>
            <tr>
              <td>4</td>
              <td>3.5%</td>
              <td>7.27%</td>
              <td>36.35%</td>
              <td>12.12%</td>
              <td>7.27%</td>
              <td>9.10%</td>
              <td>23.92%</td>
              <td>-</td>
            </tr>
            <tr>
              <td>5</td>
              <td>3.5%</td>
              <td>7.27%</td>
              <td>36.35%</td>
              <td>12.12%</td>
              <td>7.27%</td>
              <td>9.10%</td>
              <td>23.92%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>3.5%</td>
              <td>7.27%</td>
              <td>36.35%</td>
              <td>12.12%</td>
              <td>7.27%</td>
              <td>9.10%</td>
              <td>23.92%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>8</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>10.6%</td>
            </tr>
            <tr>
              <td>10</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>12.72%</td>
            </tr>
            <tr>
              <td>11</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>14.84%</td>
            </tr>
            <tr>
              <td>12</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>14.84%</td>
            </tr>
            <tr>
              <td>13</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>16.96%</td>
            </tr>
            <tr>
              <td>14</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>16.96%</td>
            </tr>
            <tr>
              <td>15</td>
              <td>4.66%</td>
              <td>9.69%</td>
              <td>48.45%</td>
              <td>16.16%</td>
              <td>9.69%</td>
              <td>12.14%</td>
              <td>31.90%</td>
              <td>19.09%</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesHarmonyData;

export const Head: React.FC = () => (
  <Seo
    title="Harmony Cubes - data | NIKKE | Prydwen Institute"
    description="Detailed information about the Harmony Cubes in NIKKE."
    game="nikke"
  />
);
